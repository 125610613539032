<template>
  <div class="setting">
    <div class="top disselect">
      {{langTxt[lang].exchange.setting[0]}}
      <span :class="{ ui_switch: true, on: switchOnTag }" @click="switchUpdates"></span>
    </div>
    <div class="msg">
      <textarea v-show="switchOnTag" v-model="msgTxt" placeholder="Message For Market"></textarea>
      <div v-show="switchOnTag" class="tips">{{ msgTxt.length }} / 80</div>
    </div>
    <div class="btn">
      <ui_btn_1 v-if="msgTxt.length < 81" :width="'1.67rem'" @click="sendPeerImNotifyStatus">
        {{langTxt[lang].exchange.setting[1]}}</ui_btn_1>
      <ui_btn_1 v-else :disclick="true" :width="'1.67rem'">{{langTxt[lang].exchange.setting[1]}}</ui_btn_1>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .top {
    color: #fff;
    text-transform: capitalize;
    font-size: 0.18rem;
    text-align: center;
    padding-top: 0.97rem;
    margin-bottom: 0.24rem;

    .ui_switch {
      display: inline-block;
      width: 0.6rem;
      height: 0.3rem;
      border-radius: 0.15rem;
      background: #ececec;
      vertical-align: middle;
      margin-left: 0.2rem;
      cursor: pointer;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0.03rem;
        left: 0.03rem;
        width: 0.24rem;
        height: 0.24rem;
        box-shadow: 1px 1px 2px -1px rgba(51, 51, 51, 0.3);
        background-color: #fff;
        border-radius: 50%;
      }

      &.on {
        background: linear-gradient(83.73deg, #ff8688 0%, #e9373e 99.75%);

        &::after {
          left: auto;
          right: 0.03rem;
        }
      }
    }
  }

  .msg {
    text-align: center;
    width: 6.89rem;
    margin: 0 auto;
    margin-bottom: 0.8rem;
    position: relative;

    textarea {
      width: 6.89rem;
      height: 2.06rem;
      background: #2a2958;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      resize: none;
      color: #fff;
      font-size: 0.16rem;
      padding: 0.12rem 0.2rem;
      box-sizing: border-box;
    }

    .tips {
      position: absolute;
      font-size: 0.16rem;
      color: rgba(255, 255, 255, 0.15);
      bottom: 0.1rem;
      right: 0.12rem;
    }
  }

  .btn {
    text-align: center;
  }
</style>
<script>
  import {
    defineComponent,
    ref,
    computed
  } from "vue";
  import {
    useStore
  } from "vuex";
  // import { useRouter } from "vue-router";
  import ui_btn_1 from "@/components/ui/ui_btn_1";
  import common from "../../../utils/common";
  import axios from "axios";

  export default defineComponent({
    name: "setting",
    props: {},
    components: {
      ui_btn_1
    },
    setup(props, ctx) {
      // let router = useRouter();
      let store = useStore();
      let switchOnTag = ref(0);
      let msgTxt = ref("");
      const switchUpdates = function () {
        if (!switchOnTag.value) {
          switchOnTag.value = 1;
        } else {
          switchOnTag.value = 0;
        }
      };
      const getPeerImNotifyStatus = () => {
        const sendUrl =
          common.userApi.requestUrl + "/finance_api/market/peer_im_notify_status";
        const sendHeaders = common.buildHeaders();
        const sendData = {
          headers: sendHeaders,
        };
        common.showLoading("getPeerImNotifyStatus");
        axios
          .get(sendUrl, sendData)
          .then((r) => {
            if (r.data.code == 0) {
              switchOnTag.value = r.data.status;
              if (r.data.notify_content) {
                msgTxt.value = r.data.notify_content;
              }
            } else {
              console.error(r.data.msg);
              toastr["error"](r.data.msg);
            }
          })
          .catch((e) => {
            console.error(e);
            toastr["error"](e);
          })
          .finally(() => {
            common.hideLoading("getPeerImNotifyStatus");
          });
      };
      const sendPeerImNotifyStatus = () => {
        const sendUrl =
          common.userApi.requestUrl + "/finance_api/market/peer_im_notify_status";
        const sendHeaders = common.buildHeaders("form");
        const sendData = common.buildSendData({
          status: switchOnTag.value,
          notify_content: msgTxt.value,
        });
        common.showLoading("sendPeerImNotifyStatus");
        axios
          .patch(sendUrl, sendData, {
            headers: sendHeaders
          })
          .then((r) => {
            if (r.data.code == 0) {
              toastr["success"](r.data.msg);
              getPeerImNotifyStatus();
            } else {
              console.error(r.data.msg);
              toastr["error"](r.data.msg);
            }
          })
          .catch((e) => {
            console.error(e);
            toastr["error"](e);
          })
          .finally(() => {
            common.hideLoading("sendPeerImNotifyStatus");
          });
      };
      getPeerImNotifyStatus();
      var lang = computed(() => {
        return store.state.lang;
      });
      var langTxt = store.state.text;

      return {
        langTxt,
        lang,
        switchOnTag,
        switchUpdates,
        msgTxt,
        getPeerImNotifyStatus,
        sendPeerImNotifyStatus,
      };
    },
  });
</script>