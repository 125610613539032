<template>
  <span class="icon_channel_selected">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0L20 20L20 5C20 2.23858 17.7614 5.85789e-07 15 4.94715e-07L0 0Z"
        fill="#EF4F55"
      />
      <path
        d="M11 6.19824L13.1213 8.31956L16.3199 5.12101"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </span>
</template>
<style lang="scss" scoped>
.icon_channel_selected {
  width: 0.2rem;
  height: 0.24em;
  cursor: pointer;
  display: inline-block;
  position: relative;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
