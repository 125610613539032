<template>
  <div class="publish">
    <div class="select">
      <!-- <ui_select class="onlypc" :list="typeList" v-model:val="showType"></ui_select> -->
      <div class="ui_select">
        <span :class="{ active: showType == 1 }" @click="showType = 1">{{
          lang == "en" ? "Buy" : "買入"
        }}</span>
        <span :class="{ active: showType == 2 }" @click="showType = 2">{{
          lang == "en" ? "Sell" : "賣出"
        }}</span>
      </div>
    </div>
    <div class="content">
      <div class="top">
        <icon_red_triangle class="ico" />
        {{ lang == "en" ? "Details" : "詳情" }}
      </div>
      <div class="tb">
        <div class="tr mod">
          <div class="lable">{{ lang == "en" ? "Mode" : "交易模式" }}</div>
          <div class="val">
            <span :class="mod == 0 ? 'on' : ''" @click="mod = 0">普通模式</span
            ><span :class="mod == 1 ? 'on' : ''" @click="mod = 1">快速模式</span>
          </div>
        </div>
        <template v-if="mod == 0">
          <div class="tr">
            <div class="lable">{{ langTxt[lang].exchange.detail[9] }}</div>
            <div class="val channel">
              <div
                :class="{ icobox: true, active: channelArray.indexOf(1) >= 0 }"
                @click="selectChannel(1)"
              >
                <span data-channel="1" class="icon_channel usdt">USDT</span>
                <ico_active class="onlymobile" />
              </div>
              <div
                :class="{ icobox: true, active: channelArray.indexOf(3) >= 0 }"
                @click="selectChannel(3)"
              >
                <span data-channel="3" class="icon_channel usdc">USDC</span>
                <ico_active class="onlymobile" />
              </div>
              <div
                :class="{ icobox: true, active: channelArray.indexOf(4) >= 0 }"
                @click="selectChannel(4)"
              >
                <span data-channel="4" class="icon_channel busd">BUSD</span>
                <ico_active class="onlymobile" />
              </div>
              <div
                :class="{ icobox: true, active: channelArray.indexOf(5) >= 0 }"
                @click="selectChannel(5)"
              >
                <span data-channel="5" class="icon_channel dia">DIA</span>
                <ico_active class="onlymobile" />
              </div>
              <div
                :class="{ icobox: true, active: channelArray.indexOf(2) >= 0 }"
                @click="selectChannel(2)"
              >
                <span data-channel="2" class="icon_channel other">OTHER</span>
                <ico_active class="onlymobile" />
              </div>
            </div>
          </div>
          <div :class="{ tr: true, buy: showType == 2, sell: showType == 1 }">
            <div class="lable">
              {{ langTxt[lang].exchange.detail[2] }}
              {{
                showType == 2
                  ? langTxt[lang].exchange.detail[4]
                  : langTxt[lang].exchange.detail[3]
              }}
            </div>
            <div class="val">
              <input
                @blur="inpuBlur"
                type="text"
                onpaste="return false;"
                v-model.trim.number="quantity"
                @keydown="formatNumber"
                :placeholder="langTxt[lang].exchange.detail[16]"
              />
            </div>
          </div>
          <div :class="{ tr: true, buy: showType == 2, sell: showType == 1 }">
            <div class="lable">{{ langTxt[lang].exchange.detail[8] }}</div>
            <div class="val">
              <input
                @blur="inpuBlur"
                type="text"
                onpaste="return false;"
                v-model.trim.number="minAmount"
                @keydown="formatNumber"
                placeholder="Please Enter Quantity"
              />
            </div>
          </div>
          <div :class="{ tr: true, buy: showType == 2, sell: showType == 1 }">
            <div class="lable">{{ lang == "en" ? "trade multiplier" : "倍增基數" }}</div>
            <div class="val">
              <input
                @blur="inpuBlur"
                type="text"
                class="multiple"
                onpaste="return false;"
                v-model.trim.number="multiple"
                @keydown="formatNumber"
                :placeholder="lang == 'en' ? 'Please Enter The Multiple' : '請輸入倍數'"
              />
              <div class="multiple_tips">
                {{
                  lang == "en"
                    ? "* Selectable input multiples or no input"
                    : "*可填入倍增基數，或者留空"
                }}
              </div>
            </div>
          </div>
          <div class="tr">
            <div class="lable">{{ langTxt[lang].exchange.detail[10] }}</div>
            <div class="val">
              <textarea
                v-model="remarkNote"
                :placeholder="
                  lang == 'en' ? 'Please Enter Your Content' : '請輸入您的內容'
                "
              ></textarea>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="tr">
            <div class="lable">支持渠道</div>
            <div class="val">
              <span class="channel_item on"><ico_u />U幣<icon_channel_selected /></span>
            </div>
          </div>
          <hr />
          <div class="tr">
            <div class="lable">金幣兌換比例</div>
            <div class="val rota">
              1000&emsp;:&emsp;
              <input
                type="text"
                onpaste="return false;"
                v-model="inpuRate"
                @blur="formatFastModeRate"
              />
              <br class="onlymobile" /><icon_free>手續費限免</icon_free>
            </div>
          </div>
          <div class="tr rota">
            <el-slider
              v-model="fastModeRate"
              :step="0.01"
              :min="0.96"
              :max="1.04"
              :format-tooltip="formatTooltip"
              @change="inpuRate = fastModeRate"
            >
            </el-slider>
            <div class="min">0.96</div>
            <div class="max">1.04</div>
          </div>
          <div :class="{ tr: true, buy: showType == 2, sell: showType == 1 }">
            <div class="lable">
              {{
                showType == 2
                  ? langTxt[lang].exchange.detail[4]
                  : langTxt[lang].exchange.detail[3]
              }}數量
            </div>
            <div class="val">
              <input
                type="text"
                @blur="inpuBlur"
                onpaste="return false;"
                v-model.trim.number="quantity"
                @keydown="formatNumber"
              />
              <span class="res"
                >≈ &emsp; {{ (quantity * fastModeRate) / 1000 }}<ico_u
              /></span>
              <div class="multiple_tips">
                {{ showType == 1 ? "售出" : "買入" }} {{ quantity }}
                <Icon_gold />，將鎖定您的錢包
                {{ showType == 1 ? quantity : (quantity * fastModeRate) / 1000 }}
                <Icon_gold v-if="showType == 1" /><ico_u v-else />，請確保您有足夠的資產。
              </div>
            </div>
          </div>
          <div class="tr">
            <div class="lable">交易倍數</div>
            <div class="val multiple_box">
              <span
                :class="{ multiple_item: true, on: multiple == 5 }"
                @click="multiple != 5 ? (multiple = 5) : (multiple = 1)"
                >X5</span
              >
              <span
                :class="{ multiple_item: true, on: multiple == 10 }"
                @click="multiple != 10 ? (multiple = 10) : (multiple = 1)"
                >X10</span
              >
              <span
                :class="{ multiple_item: true, on: multiple == 100 }"
                @click="multiple != 100 ? (multiple = 100) : (multiple = 1)"
                >X100</span
              >
              <span
                :class="{ multiple_item: true, on: multiple == 1000 }"
                @click="multiple != 1000 ? (multiple = 1000) : (multiple = 1)"
                >X1000</span
              >
            </div>
          </div>
          <div :class="{ tr: true, buy: showType == 2, sell: showType == 1 }">
            <div class="lable">{{ langTxt[lang].exchange.detail[8] }}</div>
            <div class="val">
              <input
                @blur="inpuBlur"
                type="text"
                onpaste="return false;"
                v-model.trim.number="minAmount"
                @keydown="formatNumber"
                :placeholder="lang == 'en' ? 'Please Enter Quantity' : '請輸入數量'"
              />
            </div>
          </div>
          <div class="tr">
            <div class="lable">{{ langTxt[lang].exchange.detail[10] }}</div>
            <div class="val">
              <textarea
                v-model="remarkNote"
                :placeholder="
                  lang == 'en' ? 'Please Enter Your Content' : '請輸入您的內容'
                "
              ></textarea>
            </div>
          </div>
        </template>
        <div class="btn">
          <ui_btn_1 :width="'1.92rem'" @click="commfirm">{{
            langTxt[lang].exchange.detail[11]
          }}</ui_btn_1>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./publish.scss"></style>
<script>
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import ui_select from "@/components/ui/ui_select";
import ui_btn_1 from "@/components/ui/ui_btn_1";
import common from "../../../utils/common";
import axios from "axios";
import ico_active from "@/components/trades/publish/ico_active.vue";
import ico_u from "../../ui/icon/icon_u.vue";
import icon_channel_selected from "../../ui/icon/icon_channel_selected.vue";
import Icon_gold from "../../ui/icon/icon_gold.vue";
import icon_free from "../..//ui/icon/icon_free.vue";
import icon_red_triangle from "../../ui/icon/icon_red_triangle.vue";

export default defineComponent({
  name: "publish",
  props: {},
  components: {
    ui_select,
    ui_btn_1,
    ico_active,
    ico_u,
    icon_channel_selected,
    Icon_gold,
    icon_free,
    icon_red_triangle,
  },
  setup(props, ctx) {
    // let router = useRouter();
    let store = useStore();
    let typeList = [
      {
        name:
          "Sell  <i style='display:inline-block;width:0.08rem;height:0.08rem;background: #C1FB00;margin-left: 0.1rem;'></i>",
        val: 1,
        default: true,
      },
      {
        name:
          "Buy <i style='display:inline-block;width:0.08rem;height:0.08rem;background: #FFB710;margin-left: 0.1rem;'></i>",
        val: 2,
        default: false,
      },
    ];

    let showType = ref(1);

    //交易渠道列表
    let channelArray = ref([1, 2, 3, 4, 5]);
    const selectChannel = (c) => {
      let inx = channelArray.value.indexOf(c);
      // console.log(c, inx);
      if (inx >= 0) {
        channelArray.value.splice(inx, 1);
      } else {
        channelArray.value.push(c);
      }
      // console.log(channelArray.value.toString());
    };

    let quantity = ref(1000);
    let minAmount = ref(1);
    let multiple = ref(1);
    // 檢查三個輸入框之間的關係
    const inpuBlur = () => {
      // 最小額不能大於全額
      if (minAmount.value * 1 > quantity.value * 1) {
        minAmount.value = quantity.value * 1;
      }
      // 倍率不能大於最小額，且最小額求餘培增基數須為0
      // if (multiple.value > 0) {
      //   if (multiple.value * 1 > minAmount.value * 1) {
      //     multiple.value = minAmount.value * 1;
      //   }
      //   else if ((minAmount.value * 1) % (multiple.value * 1) != 0) {
      //     multiple.value = minAmount.value * 1;
      //   }
      // }
    };
    //限制輸入
    const formatNumber = ($event) => {
      var keyCode = $event.key;
      if (
        keyCode >= 0 ||
        keyCode == "Backspace" ||
        keyCode == "ArrowLeft" ||
        keyCode == "ArrowRight"
      ) {
        $event.returnValue = true;
        return true;
      } else {
        $event.returnValue = false;
        return false;
      }
    };

    let inpuRate = ref(0.96);

    const formatFastModeRate = () => {
      if (inpuRate.value > 0) {
        let V = parseFloat(inpuRate.value);
        console.log(inpuRate.value, V, typeof V);
        if (V > 1.04) {
          fastModeRate.value = 1.04;
        } else if (V < 0.96) {
          fastModeRate.value = 0.96;
        } else {
          fastModeRate.value = V;
        }
        inpuRate.value = fastModeRate.value;
        formatTooltip(fastModeRate.value);
      } else {
        inpuRate.value = fastModeRate.value = 0.96;
        if (lang.value == "en") {
          toastr["error"]("Please enter the number");
        } else {
          toastr["error"]("請輸入數字!");
        }
      }
    };

    // 監控倍率
    watch(multiple, (newVal, oldVal) => {
      inpuBlur();
      return multiple.value;
    });

    //remark
    let remarkNote = ref(null);

    const commfirm = () => {
      let myLevel = store.state.userInfo.levle;
      let limitLevel = store.state.MarketUserLevelLimit;
      if (
        showType.value == 1 &&
        limitLevel.create_public_sell_order_min_level > myLevel
      ) {
        toastr["error"]("Your level is below the requirement");
        return;
      } else if (
        showType.value == 2 &&
        limitLevel.create_public_buy_order_min_level > myLevel
      ) {
        toastr["error"]("Your level is below the requirement");
        return;
      }

      if (quantity.value * 1 < 1) {
        toastr["error"]("Quantity cannot Be 0");
      } else if (minAmount.value * 1 < 0) {
        toastr["error"]("Minmum Amount has to be greater than 0");
      } else if (quantity.value * 1 > 0 && minAmount.value * 1 >= 0) {
        if (quantity.value * 1 >= minAmount.value * 1) {
          const sendUrl = common.userApi.requestUrl + "/finance_api/market/public_orders";
          const sendHeaders = common.buildHeaders("form");
          const sendData = common.buildSendData({
            asset_type: 2,
            count: quantity.value,
            type: showType.value == 1 ? 2 : 1,
            trans_count_lower_bound: minAmount.value,
            remark: remarkNote.value,
            trans_channels: mod.value == 0 ? channelArray.value.toString() : "1,2,3,4,5",
            round_multiple: multiple.value ? multiple.value : 0,
            mode: mod.value,
            ratio: fastModeRate.value * 10000, //9600--14000這個範圍
          });
          console.log(sendData);
          common.showLoading("publish commfirm");
          axios
            .post(sendUrl, sendData, {
              headers: sendHeaders,
            })
            .then((r) => {
              console.log(r);
              if (r.data.code == 0) {
                toastr["success"](r.data.msg);
              } else {
                toastr["error"](r.data.msg);
              }
            })
            .catch((e) => {
              console.error(e);
            })
            .finally(() => {
              common.hideLoading("publish commfirm");
            });
        } else {
          toastr["error"]("Quantity must be greater than Minmum Amount");
        }
      } else {
        toastr["error"]("Input is not a number");
      }
    };

    var lang = computed(() => {
      return store.state.lang;
    });
    var langTxt = store.state.text;

    let mod = ref(0); //如果為0或者不填則為默認的交易模式，1為快速交易模式
    let fastModeRate = ref(0.96);
    // 格式化滑塊的提示文字
    const formatTooltip = (inx) => {
      return inx;
    };

    return {
      formatTooltip,
      mod,
      fastModeRate,
      langTxt,
      lang,
      typeList,
      showType,
      selectChannel,
      channelArray,
      quantity,
      minAmount,
      formatNumber,
      formatFastModeRate,
      inpuRate,
      commfirm,
      remarkNote,
      multiple,
      inpuBlur,
    };
  },
});
</script>
