<template>
  <div class="detail_box onlypc">
    <div class="quantity" v-if="showInputTag && currentItem.status == 0">
      <div class="slider" v-if="currentItem.round_multiple != 0">
        <el-slider
          v-if="currentItem.uinfo.uid != myUid && currentItem.round_multiple != 0"
          v-model="quantity"
          :step="currentItem.round_multiple"
          :min="currentItem.trans_count_lower_bound"
          :max="currentItem.remain_count"
          :format-tooltip="formatTooltip"
        >
        </el-slider>
        <!-- <div class="n disselect">{{N}} × {{currentItem.round_multiple}}</div> -->
      </div>
      <div
        v-if="currentItem.uinfo.uid != myUid && currentItem.round_multiple != 0"
        class="input"
      >
        <div
          @mouseenter="showDarkTips"
          @mouseleave="hideDarkTips"
          @click="quantity -= currentItem.round_multiple"
          :class="{ left: true, dis: currentItem.trans_count_lower_bound == quantity }"
        >
          <svg
            width="12"
            height="2"
            viewBox="0 0 12 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="12" height="2" fill="white" />
          </svg>
        </div>
        <div
          @mouseenter="showDarkTips"
          @mouseleave="hideDarkTips"
          @click="quantity += currentItem.round_multiple"
          :class="{
            right: true,
            dis: quantity > currentItem.remain_count - currentItem.round_multiple,
          }"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 5H12V7H0V5Z" fill="white" />
            <path d="M5 12L5 8.74227e-08L7 0L7 12H5Z" fill="white" />
          </svg>
        </div>
        <input
          type="number"
          @blur="checkInputQuantity"
          class="input_quantity"
          v-model.number.trim="inputQuantity"
        />
      </div>
      <input
        v-show="currentItem.uinfo.uid != myUid && currentItem.round_multiple == 0"
        type="text"
        name=""
        id="number"
        onpaste="return false;"
        :placeholder="langTxt[lang].exchange.detail[1]"
        v-model.trim.number="quantity"
        @keydown="formatNumber"
      />
      <ui_btn_1
        v-if="currentItem.uinfo.uid == myUid"
        :width="'1.67rem'"
        :height="'0.48rem'"
        @click="cancelOrder"
      >
        {{ langTxt[lang].exchange.detail[0] }}
      </ui_btn_1>
      <ui_btn_1 v-else :width="'1.67rem'" :height="'0.48rem'" @click="comfirmOrder"
        >{{ langTxt[lang].exchange.detail[2] }}
        {{
          currentItem.type == 1
            ? langTxt[lang].exchange.detail[3]
            : langTxt[lang].exchange.detail[4]
        }}</ui_btn_1
      >
    </div>
    <div class="status mortina calceled" v-else-if="currentItem.status == 1">
      {{ langTxt[lang].exchange.status[2] }}
    </div>
    <div class="status mortina frozen" v-else-if="currentItem.status == 2">
      {{ langTxt[lang].exchange.status[3] }}
    </div>
    <div class="status mortina completed" v-else-if="currentItem.status == 3">
      {{ langTxt[lang].exchange.status[1] }}
    </div>
    <div class="content">
      <div class="top">
        <span class="mortina">{{ langTxt[lang].exchange.detail[5] }}</span
        ><span>{{ formatDateTime(currentItem.add_time) }}</span>
      </div>
      <div class="table">
        <div class="tr">
          <div class="label">ID</div>
          <div class="val">{{ currentItem.uinfo.uid }}</div>
          <div class="label">{{ langTxt[lang].exchange.detail[6] }}</div>
          <div class="val">{{ currentItem.uinfo.nick }}</div>
        </div>
        <div
          :class="{
            tr: true,
            buy: currentItem.type == 2,
            sell: currentItem.type == 1,
          }"
        >
          <div class="label">{{ langTxt[lang].exchange.detail[7] }}</div>
          <div class="val">
            <img :src="cdnUrl + '/web_assets/img/ico_exchange_gold.png'" alt="" />{{
              currentItem.remain_count
            }}
            <span
              @click="addCount"
              class="add_count"
              v-if="currentItem.uinfo.uid == myUid && currentItem.status == 0"
              ><svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6 13H22V15H6V13Z" fill="#EF4F55" />
                <path d="M13 22L13 6L15 6L15 22H13Z" fill="#EF4F55" />
                <rect
                  x="1"
                  y="1"
                  width="26"
                  height="26"
                  rx="13"
                  stroke="#EF4F55"
                  stroke-width="2"
                />
              </svg>
            </span>
          </div>
          <div class="label">{{ langTxt[lang].exchange.detail[8] }}</div>
          <div class="val">
            <img :src="cdnUrl + '/web_assets/img/ico_exchange_gold.png'" alt="" />{{
              currentItem.trans_count_lower_bound
            }}
          </div>
        </div>
        <div class="tr" v-if="currentItem.round_multiple > 0">
          <div class="label">{{ lang == "en" ? "trade multiplier" : "倍增基數" }}</div>
          <div class="val">{{ currentItem.round_multiple }}</div>
        </div>
        <div class="tr">
          <div class="label">{{ langTxt[lang].exchange.detail[9] }}</div>
          <div class="val">
            <div class="channel detail" v-html="icoHtml"></div>
          </div>
        </div>
        <div class="tr note">
          <div class="label">{{ langTxt[lang].exchange.detail[10] }}</div>
          <div class="val">
            {{ currentItem.remark == "null" ? "" : currentItem.remark }}
          </div>
        </div>
      </div>
      <div class="im" v-if="currentItem.uinfo.uid != myUid">
        <span class="talk" @click="openTalk">
          <img :src="cdnUrl + '/web_assets/img/ico_talk.png'" alt="" />Hash talk</span
        ><span v-if="!isInElectron" class="contact" @click="openChat"
          ><img :src="cdnUrl + '/web_assets/img/ico_contact.png'" alt="" />Contact
          me</span
        >
      </div>
    </div>
    <teleport to="#app">
      <div class="add_count_input" v-if="showAddCountInput">
        <input
          v-model.number.trim="addCountNum"
          type="number"
          placeholder="請輸入追加的金幣金額"
        />
      </div>
    </teleport>
  </div>
  <div class="detail_box onlymobile">
    <div class="content">
      <div class="top">
        <span class="mortina">{{ langTxt[lang].exchange.detail[5] }}</span>
        <!-- <span>{{ formatDateTime(currentItem.add_time) }}</span> -->
      </div>
      <ul class="table">
        <li :class="{ buy: currentItem.type == 2, sell: currentItem.type == 1 }">
          <div class="label">{{ langTxt[lang].exchange.detail[7] }}</div>
          <div class="val">
            <div>
              {{ currentItem.remain_count }}
              <span
                @click="addCount"
                class="add_count"
                v-if="currentItem.uinfo.uid == myUid && currentItem.status == 0"
                ><svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6 13H22V15H6V13Z" fill="#EF4F55" />
                  <path d="M13 22L13 6L15 6L15 22H13Z" fill="#EF4F55" />
                  <rect
                    x="1"
                    y="1"
                    width="26"
                    height="26"
                    rx="13"
                    stroke="#EF4F55"
                    stroke-width="2"
                  />
                </svg>
              </span>
            </div>
            <img :src="cdnUrl + '/web_assets/img/ico_exchange_gold.png'" alt="" />
          </div>
        </li>
        <li :class="{ buy: currentItem.type == 2, sell: currentItem.type == 1 }">
          <div class="label">{{ langTxt[lang].exchange.detail[8] }}</div>
          <div class="val">
            <div>{{ currentItem.trans_count_lower_bound }}</div>
            <img :src="cdnUrl + '/web_assets/img/ico_exchange_gold.png'" alt="" />
          </div>
        </li>
        <li>
          <div class="label">{{ lang == "en" ? "trade multiplier" : "倍增基數" }}</div>
          <div class="val">{{ currentItem.round_multiple }}</div>
        </li>
        <li>
          <div class="label">{{ langTxt[lang].exchange.detail[9] }}</div>
          <div class="val">
            <div class="channel detail" v-html="icoHtml"></div>
          </div>
        </li>
        <li>
          <div class="label">{{ lang == "en" ? "Time" : "時間" }}</div>
          <div class="val">{{ formatDateTime(currentItem.add_time) }}</div>
        </li>
        <li>
          <div class="label">ID</div>
          <div class="val">{{ currentItem.uinfo.uid }}</div>
        </li>
        <li>
          <div class="label">{{ langTxt[lang].exchange.detail[6] }}</div>
          <div class="val">{{ currentItem.uinfo.nick }}</div>
        </li>
        <li>
          <div class="label">{{ langTxt[lang].exchange.detail[10] }}</div>
          <div class="val">
            {{ currentItem.remark == "null" ? "" : currentItem.remark }}
          </div>
        </li>
      </ul>
      <div class="quantity" v-if="showInputTag && currentItem.status == 0">
        <div class="slider" v-if="currentItem.round_multiple != 0">
          <el-slider
            v-if="currentItem.uinfo.uid != myUid && currentItem.round_multiple != 0"
            v-model="quantity"
            :step="currentItem.round_multiple"
            :min="currentItem.trans_count_lower_bound"
            :max="currentItem.remain_count"
            :format-tooltip="formatTooltip"
          >
          </el-slider>
          <!-- <div class="n disselect">{{N}} × {{currentItem.round_multiple}}</div> -->
        </div>
        <div
          v-if="currentItem.uinfo.uid != myUid && currentItem.round_multiple != 0"
          class="input"
        >
          <div
            @mouseenter="showDarkTips"
            @mouseleave="hideDarkTips"
            @click="quantity -= currentItem.round_multiple"
            :class="{ left: true, dis: currentItem.trans_count_lower_bound == quantity }"
          >
            <svg
              width="12"
              height="2"
              viewBox="0 0 12 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="12" height="2" fill="white" />
            </svg>
          </div>
          <div
            @mouseenter="showDarkTips"
            @mouseleave="hideDarkTips"
            @click="quantity += currentItem.round_multiple"
            :class="{
              right: true,
              dis: quantity > currentItem.remain_count - currentItem.round_multiple,
            }"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 5H12V7H0V5Z" fill="white" />
              <path d="M5 12L5 8.74227e-08L7 0L7 12H5Z" fill="white" />
            </svg>
          </div>
          <input
            type="number"
            @blur="checkInputQuantity"
            class="input_quantity"
            v-model.number.trim="inputQuantity"
          />
        </div>
        <input
          v-show="currentItem.uinfo.uid != myUid && currentItem.round_multiple == 0"
          type="text"
          name=""
          id="number"
          onpaste="return false;"
          :placeholder="langTxt[lang].exchange.detail[1]"
          v-model.trim.number="quantity"
          @keydown="formatNumber"
        />
        <div class="im" v-if="currentItem.uinfo.uid != myUid" @click="openChat">
          <icon_chat />
        </div>
        <ui_btn_1
          v-if="currentItem.uinfo.uid == myUid"
          :width="'1.67rem'"
          :height="'0.48rem'"
          @click="cancelOrder"
        >
          {{ langTxt[lang].exchange.detail[0] }}
        </ui_btn_1>
        <ui_btn_1 v-else :width="'1.67rem'" :height="'0.48rem'" @click="comfirmOrder"
          >{{ langTxt[lang].exchange.detail[2] }}
          {{
            currentItem.type == 1
              ? langTxt[lang].exchange.detail[3]
              : langTxt[lang].exchange.detail[4]
          }}</ui_btn_1
        >
      </div>
      <div class="status mortina calceled" v-else-if="currentItem.status == 1">
        {{ langTxt[lang].exchange.status[2] }}
      </div>
      <div class="status mortina frozen" v-else-if="currentItem.status == 2">
        {{ langTxt[lang].exchange.status[3] }}
      </div>
      <div class="status mortina completed" v-else-if="currentItem.status == 3">
        {{ langTxt[lang].exchange.status[1] }}
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./order_detail.scss"></style>
<style lang="scss">
.channel.detail {
  span.icon_channel {
    margin: 0 0.1rem;
  }
}

#hashtalk_pc {
  .el-slider__runway {
    height: 0.16rem;
    background-color: #fff;
    border: 1px solid #007aff;
    border-radius: 0.08rem;
  }
  .detail_box .el-slider__button {
    background-color: #007aff;
  }
  .detail_box .el-slider__bar {
    background-color: #007aff;
  }
}
.detail_box {
  .btn1 .text {
    font-size: 0.16rem;
  }

  .el-slider__runway {
    height: 0.16rem;
    background-color: #1c1b3a;
    border-radius: 0.08rem;
  }

  .el-slider__bar {
    height: 0.16rem;
    border-radius: 0.08rem;
    background-color: #38366c;
  }

  .el-slider__stop {
    display: none;
  }

  .el-slider__button-wrapper {
    top: -10px;

    .hover_tips {
      background: #38366c;
      position: absolute;
      border-radius: 4px;
      padding: 5px 11px;
      z-index: 2000;
      font-size: 12px;
      line-height: 20px;
      width: auto;
      white-space: nowrap;
      top: 0;
      left: 50%;
      transform: translate(-50%, -130%);
      visibility: visible;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 10px;
        height: 10px;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #38366c;
      }
    }
  }

  .el-slider__button {
    width: 0.3rem;
    height: 0.3rem;
    background-color: #38366c;
    border-color: #fff;
    box-shadow: 0px 0px 4px rgba(12, 11, 49, 0.7);
  }
}

.el-popper.is-dark {
  background: #38366c;
}

.el-popper.is-dark .el-popper__arrow::before {
  background: #38366c;
}
</style>
<script src="./order_detail.js"></script>
