<template>
  <div class="exchange layout_left_right" :id="fromClient">
    <div class="layout">
      <div class="layout_left">
        <ui_side_menu
          @updateIndex="switchItem"
          :sideMenuList="leftList"
          :activeIndex="activeIndex"
        ></ui_side_menu>
      </div>
      <div class="layout_right" v-if="uid">
        <!-- <div v-if="displayStatus"> -->
        <my_trades v-if="activeIndex == 0"></my_trades>
        <hall v-else-if="activeIndex == 1"></hall>
        <setting v-else-if="activeIndex == 3"></setting>
        <publish v-else-if="activeIndex == 2"></publish>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import ui_select from "@/components/ui/ui_select";
import hall from "@/components/trades/hall/hall.vue";
import my_trades from "@/components/trades/my_trades/my_trades.vue";
import setting from "@/components/trades/setting/setting.vue";
import publish from "@/components/trades/publish/publish.vue";
import ui_side_menu from "@/components/ui/ui_side_menu.vue";
import common from "../utils/common";
import axios from "axios";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "exchange",
  components: {
    ui_select,
    hall,
    my_trades,
    setting,
    publish,
    ui_side_menu,
  },
  setup() {
    let commonIsProduction = common.isProduction;
    let fromClient = common.getQueryVariable("from");
    let store = useStore();
    let router = useRouter();
    var lang = computed(() => {
      return store.state.lang;
    });
    var langTxt = store.state.text;
    let leftList = ref(langTxt[lang.value].exchange.menu);
    watch(lang, () => {
      leftList.value = langTxt[lang.value].exchange.menu;
    });
    let activeIndex = computed(() => {
      if (router.currentRoute.value.query.activeindex) {
        return router.currentRoute.value.query.activeindex;
      } else {
        return 1;
      }
    });
    const switchItem = (v) => {
      console.log("inx", v);
      // activeIndex.value = inx;
      router.push({ path: "/exchange", query: { activeindex: v } });
    };
    let uid = computed(() => {
      return store.state.uid;
    });

    // 获取交易所用户等级限制信息
    const getMarketUserLevelLimit = () => {
      const sendUrl =
        common.userApi.requestUrl + "/finance_api/market/market_user_level_limit";
      const sendHeaders = common.buildHeaders("form");
      common.showLoading("getMarketUserLevelLimit");
      axios
        .get(sendUrl, {
          headers: sendHeaders,
        })
        .then((r) => {
          console.log(r);
          if (r.data.code == 0) {
            store.commit("set_MarketUserLevelLimit", r.data.info);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          common.hideLoading("getMarketUserLevelLimit");
        });
    };

    // 获取交易所的显示状态(支持游客查询)
    let displayStatus = ref(false);
    const getDisplaySStatus = async () => {
      // common.showLoading("getDisplaySStatus");
      // const sendUrl =
      //   common.userApi.requestUrl + "/finance_api/market/display_status";
      // const sendHeaders = common.buildHeaders();
      // const sendData = {
      //   headers: sendHeaders,
      // };
      // axios
      //   .get(sendUrl, sendData)
      //   .then((r) => {
      //     if (r.data.code == 0) {
      //       displayStatus.value = r.data.status;
      //     }
      //   })
      //   .catch((e) => {
      //     console.error(e);
      //   })
      //   .finally(() => {
      //     common.hideLoading("getDisplaySStatus");
      //   });
    };

    //监控uid。如果没有uid，显示登录框
    if (!uid.value && !common.userApi.token) {
      common.showLogin();
    } else {
      common.queryFinance();
      getMarketUserLevelLimit();
    }
    watch(uid, (newValue, oldValue) => {
      console.log("uid changed", newValue, oldValue);
      if (!uid.value && !common.userApi.token) {
        common.showLogin();
      } else {
        common.queryFinance();
        getMarketUserLevelLimit();
      }
    });

    onMounted(() => {
      getDisplaySStatus();
    });

    return {
      langTxt,
      lang,
      fromClient,
      leftList,
      activeIndex,
      uid,
      displayStatus,
      switchItem,
      commonIsProduction,
    };
  },
});
</script>
<style lang="scss" src="./exchange.scss"></style>
<style lang="scss" src="./exchange_mobile.scss"></style>
