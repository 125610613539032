<template>
  <div class="my_trades template">
    <div class="orders" v-show="!currentItem">
      <div class="select">
        <ui_select :list="typeList" v-model:val="showType"></ui_select>
        <ui_select :list="orderBy" v-model:val="asc"></ui_select>
      </div>
      <div class="container">
        <ul class="ul onlymobile" v-if="dataList.length > 0">
          <li
            :class="{
              item_buy: item.type == 1,
              item_sell: item.type == 2,
              item_fast: item.mode == 1,
              off: item.status > 1,
            }"
            :data-sn="item.sn"
            v-for="(item, inx) in dataList"
            :key="inx"
            @click="item.status > 1 ? showOrderDetail(item.public_order_sn) : false"
          >
            <div class="t">
              <span class="time">{{ formatDateTime(item.modify_time) }}</span>
              <icon_chat
                @click.stop="openChat(item.from_uinfo.uid, item.to_uinfo.uid)"
              ></icon_chat>
            </div>
            <div
              :class="{ v: true, count: true, buy: item.type == 1, sell: item.type == 2 }"
            >
              <span>{{ item.count }}</span>
            </div>
            <div>
              <span class="label">Player ID</span
              ><span>{{
                item.to_uinfo.uid != myUid ? item.to_uinfo.uid : item.from_uinfo.uid
              }}</span>
            </div>
            <div>
              <span class="label">Content</span
              ><span class="text">{{ item.remark }}</span>
            </div>

            <div v-if="item.status == 0" class="status" :data-status="item.status">
              Locking gold coins after confirmation
            </div>
            <div class="btn mortina">
              <!-- item.status == 0 && from_uinfo.uid == myUid-->
              <ui_btn_2
                v-if="item.status == 0 && item.from_uinfo.uid == myUid"
                @click="setOrderStatus(item, 4)"
              >
                {{ langTxt[lang].exchange.btn[0] }}
                {{
                  item.type == 2
                    ? langTxt[lang].exchange.btn[1]
                    : langTxt[lang].exchange.btn[2]
                }}</ui_btn_2
              >
              <!-- item.status == 0 && to_uinfo.uid == myUid-->
              <ui_btn_2
                v-if="item.status == 0 && item.to_uinfo.uid == myUid"
                @click="setOrderStatus(item, 2)"
              >
                {{
                  item.type == 2
                    ? langTxt[lang].exchange.btn[2]
                    : langTxt[lang].exchange.btn[1]
                }}
                NO</ui_btn_2
              >
              <ui_btn_2
                :redback="true"
                v-if="item.status == 0 && item.to_uinfo.uid == myUid"
                @click="setOrderStatus(item, 1)"
              >
                {{
                  item.type == 2
                    ? langTxt[lang].exchange.btn[2]
                    : langTxt[lang].exchange.btn[1]
                }}
                YES</ui_btn_2
              >
              <!-- item.status == 1 && to_uinfo.uid == myUid -->
              <ui_btn_2
                v-if="item.status == 1 && item.type == 2 && item.to_uinfo.uid == myUid"
                @click="setOrderStatus(item, 4, true, 'Cancel Transaction?')"
                >{{ langTxt[lang].exchange.btn[3] }}
              </ui_btn_2>
              <ui_btn_2
                v-if="item.status == 1 && item.type == 2 && item.to_uinfo.uid == myUid"
                @click="arbitrating"
                >{{ langTxt[lang].exchange.btn[4] }}</ui_btn_2
              >
              <ui_btn_2
                :redback="true"
                v-if="item.status == 1 && item.type == 1 && item.to_uinfo.uid == myUid"
                @click="setOrderStatus(item, 3, true, 'Confirm Transaction?')"
                >Finish trade</ui_btn_2
              >
              <ui_btn_2
                v-if="item.status == 1 && item.type == 1 && item.to_uinfo.uid == myUid"
                @click="arbitrating"
                >{{ langTxt[lang].exchange.btn[4] }}</ui_btn_2
              >
              <!-- item.status == 1 && frome_uinfo.uid == myUid -->
              <ui_btn_2
                :redback="true"
                v-if="item.status == 1 && item.type == 2 && item.from_uinfo.uid == myUid"
                @click="setOrderStatus(item, 3, true, 'Confirm Transaction?')"
                >Finish trade</ui_btn_2
              >
              <ui_btn_2
                v-if="item.status == 1 && item.type == 2 && item.from_uinfo.uid == myUid"
                @click="arbitrating"
                >{{ langTxt[lang].exchange.btn[4] }}</ui_btn_2
              >
              <ui_btn_2
                v-if="item.status == 1 && item.type == 1 && item.from_uinfo.uid == myUid"
                @click="setOrderStatus(item, 4, true, 'Cancel Transaction?')"
                >{{ langTxt[lang].exchange.btn[3] }}
              </ui_btn_2>
              <ui_btn_2
                v-if="item.status == 1 && item.type == 1 && item.from_uinfo.uid == myUid"
                @click="arbitrating"
                >{{ langTxt[lang].exchange.btn[4] }}</ui_btn_2
              >
              <!-- item.status: 2、3、4、5 -->
              <i class="rejected" v-if="item.status == 2">{{
                langTxt[lang].exchange.status[0]
              }}</i>
              <i class="completed" v-if="item.status == 3">{{
                langTxt[lang].exchange.status[1]
              }}</i>
              <i class="canceled" v-if="item.status == 4">{{
                langTxt[lang].exchange.status[2]
              }}</i>
              <i class="frozened" v-if="item.status == 5">{{
                langTxt[lang].exchange.status[3]
              }}</i>
            </div>
          </li>
        </ul>
        <dl class="ul onlypc" v-if="dataList.length > 0">
          <dt>
            <span class="count">{{ langTxt[lang].exchange.my[0] }}</span>
            <span class="id">{{ langTxt[lang].exchange.my[1] }}</span>
            <span class="time">{{ langTxt[lang].exchange.my[2] }}</span>
            <span class="status">{{ langTxt[lang].exchange.my[3] }}</span>
            <span class="btn">{{ langTxt[lang].exchange.my[4] }}</span>
          </dt>
          <dd
            :data-sn="item.sn"
            v-for="(item, inx) in dataList"
            :key="inx"
            :class="{
              item_buy: item.type == 1,
              item_sell: item.type == 2,
              item_fast: item.mode == 1,
              off: item.status > 1,
            }"
            @click="item.status > 1 ? showOrderDetail(item.public_order_sn) : false"
          >
            <span
              :class="{
                count: true,
                buy: item.type == 1,
                sell: item.type == 2,
              }"
              >{{ item.count }}</span
            >
            <span class="id"
              >ID:
              {{ item.to_uinfo.uid != myUid ? item.to_uinfo.uid : item.from_uinfo.uid }}
              <div class="im">
                <span
                  class="talk"
                  @click.stop="openTalk(item.from_uinfo.uid, item.to_uinfo.uid)"
                ></span
                ><span
                  class="contact"
                  v-if="!isInElectron"
                  @click.stop="openChat(item.from_uinfo.uid, item.to_uinfo.uid)"
                ></span>
              </div>
            </span>
            <span class="time">{{ formatDateTime(item.modify_time) }}</span>
            <span class="status" :data-status="item.status">
              {{ item.status == 0 ? "Locking gold coins after confirmation" : "" }}</span
            >
            <span class="btn mortina">
              <!-- item.status == 0 && from_uinfo.uid == myUid-->
              <ui_btn_2
                v-if="item.status == 0 && item.from_uinfo.uid == myUid"
                @click="setOrderStatus(item, 4)"
              >
                {{ langTxt[lang].exchange.btn[0] }}
                {{
                  item.type == 2
                    ? langTxt[lang].exchange.btn[1]
                    : langTxt[lang].exchange.btn[2]
                }}</ui_btn_2
              >
              <!-- item.status == 0 && to_uinfo.uid == myUid-->
              <ui_btn_2
                v-if="item.status == 0 && item.to_uinfo.uid == myUid"
                @click="setOrderStatus(item, 2)"
              >
                {{
                  item.type == 2
                    ? langTxt[lang].exchange.btn[2]
                    : langTxt[lang].exchange.btn[1]
                }}
                NO</ui_btn_2
              >
              <ui_btn_2
                :redback="true"
                v-if="item.status == 0 && item.to_uinfo.uid == myUid"
                @click="setOrderStatus(item, 1)"
              >
                {{
                  item.type == 2
                    ? langTxt[lang].exchange.btn[2]
                    : langTxt[lang].exchange.btn[1]
                }}
                YES</ui_btn_2
              >
              <!-- item.status == 1 && to_uinfo.uid == myUid -->
              <ui_btn_2
                v-if="item.status == 1 && item.type == 2 && item.to_uinfo.uid == myUid"
                @click="setOrderStatus(item, 4, true, 'Cancel Transaction?')"
                >{{ langTxt[lang].exchange.btn[3] }}
              </ui_btn_2>
              <ui_btn_2
                v-if="item.status == 1 && item.type == 2 && item.to_uinfo.uid == myUid"
                @click="arbitrating"
                >{{ langTxt[lang].exchange.btn[4] }}</ui_btn_2
              >
              <ui_btn_2
                :redback="true"
                v-if="item.status == 1 && item.type == 1 && item.to_uinfo.uid == myUid"
                @click="setOrderStatus(item, 3, true, 'Confirm Transaction?')"
                >Finish trade</ui_btn_2
              >
              <ui_btn_2
                v-if="item.status == 1 && item.type == 1 && item.to_uinfo.uid == myUid"
                @click="arbitrating"
                >{{ langTxt[lang].exchange.btn[4] }}</ui_btn_2
              >
              <!-- item.status == 1 && frome_uinfo.uid == myUid -->
              <ui_btn_2
                :redback="true"
                v-if="item.status == 1 && item.type == 2 && item.from_uinfo.uid == myUid"
                @click="setOrderStatus(item, 3, true, 'Confirm Transaction?')"
                >Finish trade</ui_btn_2
              >
              <ui_btn_2
                v-if="item.status == 1 && item.type == 2 && item.from_uinfo.uid == myUid"
                @click="arbitrating"
                >{{ langTxt[lang].exchange.btn[4] }}</ui_btn_2
              >
              <ui_btn_2
                v-if="item.status == 1 && item.type == 1 && item.from_uinfo.uid == myUid"
                @click="setOrderStatus(item, 4, true, 'Cancel Transaction?')"
                >{{ langTxt[lang].exchange.btn[3] }}
              </ui_btn_2>
              <ui_btn_2
                v-if="item.status == 1 && item.type == 1 && item.from_uinfo.uid == myUid"
                @click="arbitrating"
                >{{ langTxt[lang].exchange.btn[4] }}</ui_btn_2
              >
              <!-- item.status: 2、3、4、5 -->
              <i class="rejected" v-if="item.status == 2">{{
                langTxt[lang].exchange.status[0]
              }}</i>
              <i class="completed" v-if="item.status == 3">{{
                langTxt[lang].exchange.status[1]
              }}</i>
              <i class="canceled" v-if="item.status == 4">{{
                langTxt[lang].exchange.status[2]
              }}</i>
              <i class="frozened" v-if="item.status == 5">{{
                langTxt[lang].exchange.status[3]
              }}</i>
            </span>
          </dd>
        </dl>
        <div class="null" v-else>
          <p>— NO NEWS YET —</p>
        </div>
        <ui_page_btn
          :dataLength="dataList.length"
          :count="count"
          :offset="offset"
          @turnPage="getMyOrders"
        >
        </ui_page_btn>
      </div>
    </div>
    <div class="order_detail" v-show="currentItem">
      <div class="back">
        <span @click="currentItem = null"
          ><i></i>{{ langTxt[lang].exchange.btn[5] }}</span
        >
      </div>
      <order_detail
        v-if="currentItem"
        :current_item="currentItem"
        :sn="currentItem.sn"
        :offset="offset"
      ></order_detail>
    </div>
    <feedback v-if="showfeedbackTag" v-model="showfeedbackTag"></feedback>
  </div>
</template>
<style lang="scss" src="./my_trades.scss"></style>
<script src="./my_trades"></script>
