<template>
  <div class="feedback">
    <div class="box">
      <h1>Feedback</h1>
      <h2><span>Feedback Type</span></h2>
      <div class="radio">
        <span @click="feedbackType = item.val" v-for="(item, index) in feedbackTypeList" :key="index"><i
            :class="item.val == feedbackType ? 'active' : ''"></i>{{ item.name }}</span>
      </div>
      <h2><span>content description</span></h2>
      <textarea v-model="feedbackContent"></textarea>
      <!-- <h2><span>upload screenshot</span></h2>
      <div class="upload">
        <span class="upload_btn">
          <input type="file" accept="image/png, image/jpeg" required @change="changeFile" />
        </span>
      </div> -->
      <div class="btn">
        <span @click="close">Cancel</span>
        <span class="confirm" @click="sendFeedback">Submit</span>
      </div>
      <span class="close" @click="close"></span>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./feedback.scss"></style>
<script setup>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import ui_btn_1 from "@/components/ui/ui_btn_1";
import common from "../../utils/common";
import axios from "axios";

let props = defineProps(['modelValue']);
let emits = defineEmits(['update:modelValue'])
// let router = useRouter();
let store = useStore();

const close = () => {
  emits('update:modelValue', false);
};

// type:类型, 1BUG反馈, 2投诉建议, 3代理合作,4其他问题,5,交易反馈
const feedbackTypeList = [
  {
    name: "Bug",
    val: 1,
  },
  {
    name: "Suggestions",
    val: 2,
  },
  {
    name: "Acting",
    val: 5,
  },
  {
    name: "Other",
    val: 4,
  },
];
let feedbackType = ref(5);
let feedbackContent = ref('')

//上传
let uploadFile = ref("");
const changeFile = (e) => {
  const file = e.target.files[0];
  var formData = new FormData();
  formData.append("file", file);
  console.log('--lb', formData.get("file"));
  if (file.size > 5000000) {
    toastr["error"]("The image cannot be larger than 5M");
  } else {
    /* //获取上传token
    // 生成用于文件上传下载的signature
    // /misc_api/file_signatures
    const sendUrl = common.userApi.requestUrl + "/misc_api/file_signatures";
    const sendData = common.buildSendData({
      file_type: 4, //file_type 文件类型, 4为用户上报图片, 5为im聊天图片,6为日志
      file_name: file.name,
      op_type: 2, //操作类型, 1为下载，2为上传
    });
    const sendHeaders = common.buildHeaders("form");
    axios
      .put(sendUrl, sendData, { headers: sendHeaders })
      .then((r) => {
        if (r.data.code == 0) {
          const signature = r.data.signature;
          const sendUrl = common.userApi.requestUrl + "/public/upload";
          const sendData = common.buildSendData(formData);
          const sendHeaders = common.buildHeaders("form", {
            "X-Upload-Token": signature,
          });
          axios.post(sendUrl, sendData, { headers: sendHeaders });
        }
      })
      .catch()
      .finally(); */
  }
};
const sendFeedback = () => {
  if (!feedbackContent.value) {
    toastr['error']('Please Input Content Description.');
    return;
  }
  const sendUrl = common.userApi.requestUrl + '/misc_api/user_reports';
  const sendData = common.buildSendData({
    type: feedbackType.value,
    content: feedbackContent.value
  });
  axios.put(sendUrl, sendData, { header: common.buildHeaders('form') }).then(() => {
    if (r.data.code == 0) {
      toastr['success'](r.data.msg);
      close();
    }
    else {
      toastr['error'](r.data.msg);
    }
  })
 };

    //获取交易仲裁员的uid，type为3
    // /misc_api/user_reports
    //客户端上传接口 (multipart/form-data)
    // const sendUrl = common.userApi + '/misc_api/sys_config/items?names=trans_arbitrator'
</script>
