<template>
  <div class="hall template">
    <div class="orders" v-show="!showOrderDetailTag">
      <div class="select">
        <ui_select :list="typeList" v-model:val="showType"></ui_select>
        <ui_select :list="orderBy" v-model:val="asc"></ui_select>
      </div>
      <dl v-if="ordersList.length > 0" class="ul onlypc">
        <dt>
          <div class="amount">{{ langTxt[lang].exchange.hall[0] }}</div>
          <div class="min">{{ langTxt[lang].exchange.hall[1] }}</div>
          <div class="note">{{ langTxt[lang].exchange.hall[2] }}</div>
          <div class="channel">{{ langTxt[lang].exchange.hall[3] }}</div>
          <div class="date">{{ langTxt[lang].exchange.hall[4] }}</div>
        </dt>
        <dd
          @click="showOrderDetail(item)"
          v-for="item in ordersList"
          :key="item.sn"
          :sn="item.sn"
          :class="{
            item_buy: item.type == 1,
            item_sell: item.type == 2,
            item_fast: item.mode == 1,
          }"
        >
          <div class="amount disselect">
            <span v-if="item.asset_type == 2" :class="item.type == 2 ? 'buy' : 'sell'"
              ><i>{{ item.remain_count }}</i
              ><em>{{ item.type == 2 ? "BUY" : "SELL" }}</em></span
            >
          </div>
          <div class="min">{{ item.uinfo.nick }}</div>
          <div class="note">
            <span v-if="item.remark">{{ item.remark }}</span>
          </div>
          <div class="channel" :channels="item.trans_channels">
            <div v-html="item.formatChannels"></div>
          </div>
          <div class="date">{{ formatDateTime(item.add_time) }}</div>
          <div v-if="item.mode && item.mode == 1" class="fast">
            <icon_fast />
          </div>
        </dd>
      </dl>
      <div v-else class="null">
        <p>— NO NEWS YET —</p>
      </div>
      <ul class="ul onlymobile" v-if="ordersList.length > 0">
        <li
          @click="showOrderDetail(item)"
          v-for="item in ordersList"
          :key="item.sn"
          :sn="item.sn"
          :class="{
            item_buy: item.type == 1,
            item_sell: item.type == 2,
            item_fast: item.mode == 1,
          }"
        >
          <div class="t">
            <span>{{ item.uinfo.nick }}</span
            ><span class="time">{{ formatDateTime(item.add_time) }}</span>
          </div>
          <div :class="{ v: true, buy: item.type == 2, sell: item.type != 2 }">
            <span class="ico">{{ item.remain_count }}</span
            ><span class="channel" v-html="item.formatChannels"></span>
          </div>
          <div class="n">
            <span class="note"></span><span class="text">{{ item.remark }}</span>
          </div>
        </li>
      </ul>
      <ui_page_btn
        :dataLength="ordersList.length"
        :count="count"
        :offset="offset"
        @turnPage="getPublicOrders"
      >
      </ui_page_btn>
    </div>
    <div class="order_detail" v-show="showOrderDetailTag == true">
      <div class="back">
        <span @click="backToList"><i></i>{{ langTxt[lang].exchange.btn[5] }}</span>
      </div>
      <order_detail
        v-if="showOrderDetailTag"
        :current_item="currentItem"
        :sn="sn"
        :offset="offset"
        @updateHall="getPublicOrders"
      ></order_detail>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./hall.scss"></style>
<style lang="scss">
.channel {
  span.icon_channel {
    display: inline-block;
    width: 0.48rem;
    height: 0.48rem;
    background: no-repeat center center;
    background-size: 95% auto;
    margin-left: calc(-0.17rem);

    &:nth-child(1) {
      margin-left: 0;
    }

    &.usdt {
      background-image: url("https://cdn.blockwar.io/web_assets/img/ico_channel_usdt.png");
    }

    &.usdc {
      background-image: url("https://cdn.blockwar.io/web_assets/img/ico_channel_usdc.png");
    }

    &.busd {
      background-image: url("https://cdn.blockwar.io/web_assets/img/ico_channel_busd.png");
    }

    &.dia {
      background-image: url("https://cdn.blockwar.io/web_assets/img/ico_channel_dai.png");
    }

    &.other {
      background-image: url("https://cdn.blockwar.io/web_assets/img/ico_channel_other.png");
    }
  }
}
</style>
<script src="./hall.js"></script>
