import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";

import ui_select from "@/components/ui/ui_select.vue";
import ui_page_btn from '@/components/ui/ui_page_btn.vue';
import icon_fast from '@/components/ui/icon/icon_fast.vue'
import common from "../../../utils/common";
import axios from "axios";
import order_detail from '@/components/trades/order_detail/order_detail.vue'

export default defineComponent({
    name: "tradingHall",
    props: {},
    components: { ui_select, ui_page_btn, order_detail,icon_fast },
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();
        //交易列表的类型：所有、出售、求购
        let typeList = [
            {
                name: "All",
                val: 0,
                default: true,
            },
            {
                name: "Buy <i style='display:inline-block;width:0.08rem;height:0.08rem;background: #FFB710;margin-left: 0.1rem;'></i>",
                val: 2,
                default: false,
            },
            {
                name: "Sell <i style='display:inline-block;width:0.08rem;height:0.08rem;background: #C1FB00;margin-left: 0.1rem;'></i>",
                val: 1,
                default: false,
            },
            {
                name: "Buy fast<i style='display:inline-block;width:0.08rem;height:0.08rem;background: #FFB710;margin-left: 0.1rem;'></i>",
                val: '2fast',
                default: false,
            },
            {
                name: "Sell fast<i style='display:inline-block;width:0.08rem;height:0.08rem;background: #C1FB00;margin-left: 0.1rem;'></i>",
                val: '1fast',
                default: false,
            },
        ];
        //交易列表的排序：最新、最旧
        let orderBy = [
            {
                name: "Ascending",
                val: 0,
                default: true,
            },
            {
                name: "Descending",
                val: 1,
                default: false,
            },
        ];
        //默认交易类型：所有
        let showType = ref(0);
        //默认排序：降序
        let asc = ref(0);
        watch(showType, (newValue, oldValue) => {
            console.log('--lb', "showType changed", newValue, oldValue);
            getPublicOrders(0);
        });
        watch(asc, (newValue, oldValue) => {
            console.log('--lb', "showType changed", newValue, oldValue);
            getPublicOrders(offset.value);
        });

        let ordersList = ref([]);
        //获取交易列表
        let offset = ref(0);//偏移量(即当前页减1)，可选默认为0，最大值只支持2000。
        let count = ref(20); //拉取的数量，可选，默认为30，最大每次不得超过50
        const getPublicOrders = (pageOffset) => {
            console.log('--lb', 'pageOffset', pageOffset)
            offset.value = pageOffset;
            console.log('--lb', 'offset.value', offset.value)
            //type:类型，1买入，2卖出,
            let typeStr = '';
            if (showType.value==1||showType.value==2) {
                typeStr = "&type=" + showType.value;
            }
            else if (showType.value=='1fast'||showType.value=='2fast') {
                typeStr = "&type=" + parseInt(showType.value)+'&mode=1';
            }
            //status:状态,0开始，1取消，2冻结, 3完成,
            // trans_channels: xx  // 交易渠道列表，多个渠道以逗号分隔, 1支付宝，2微信，3，银行卡，4，其他
            const sendUrl = common.userApi.requestUrl + '/finance_api/market/public_orders?count=' + count.value + '&offset=' + offset.value * count.value + '&statuses=0' + typeStr + '&only_self=false&ignore_zero_remain_count=true&asc=' + asc.value;
            const sendHeaders = common.buildHeaders();
            const sendData = {
                headers: sendHeaders,
            };
            common.showLoading('getPublicOrders')
            axios.get(sendUrl, sendData).then((r) => {
                if (r.data.code == 0) {
                    let res = r.data.infos;
                    for (let i = 0; i < res.length; i++) {
                        res[i].formatChannels = common.formatChannelIcon(res[i].trans_channels);
                    }
                    ordersList.value = res;
                }
                else {
                    toastr['error'](r.data.msg);
                }
            }).catch((e) => {
                console.error(e);
                toastr['error'](e);
            }).finally(
                common.hideLoading('getPublicOrders')
            );
        }
        if (common.isInElectron()) {
            common.showLoading('isInElectron timeout');
            setTimeout(function () {
                getPublicOrders(0);
                common.hideLoading('isInElectron timeout');
            }, 2000);
        }
        else {
            getPublicOrders(0);
        }
        //格式化时间
        const formatDateTime = (t) => {
            return common.formatDateTime(t);
        }
        //格式化channel图标
        const formatChannelIcon = (icoArray) => {
            return common.formatChannelIcon(icoArray);
        }

        //显示交易详情
        let showOrderDetailTag = ref(false);
        //选定查看的交易信息
        let currentItem = ref();
        let sn = ref(null);
        const showOrderDetail = (item) => {
            currentItem.value = item;
            sn.value = item.sn;
            showOrderDetailTag.value = true;
        }

        //从详情回退列表
        const backToList = () => {
            showOrderDetailTag.value = false;
            getPublicOrders(offset.value)
        }

        onMounted(() => {
        });
        var lang = computed(() => {
            return store.state.lang;
        });
        var langTxt = store.state.text;

        return {
            langTxt,
            lang, typeList, showType, asc, orderBy, ordersList, formatDateTime, showOrderDetailTag, showOrderDetail, formatChannelIcon, offset, count, currentItem, getPublicOrders, backToList, sn
        };
    },
});