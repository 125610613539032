
import {
    computed,
    defineComponent,
    onMounted,
    ref, watch
} from "vue";
import {
    useStore
} from "vuex";
// import { useRouter } from "vue-router";
import ui_btn_1 from "@/components/ui/ui_btn_1";
import common from "../../../utils/common";
import axios from "axios";
import icon_chat from '@/components/ui/icon/icon_chat.vue'

export default defineComponent({
    name: "",
    props: {
        current_item: {
            default: {}
        },
        offset: {
            default: 0,
        },
    },
    components: {
        ui_btn_1,icon_chat
    },
    emits: ["updateHall"],
    setup(props, ctx) {
        // let router = useRouter();

        let isInElectron = common.isInElectron();

        //是否顯示輸入框
        let showInputTag = ref(true);
        let store = useStore();
        let cdnUrl = computed(() => {
            return store.state.cdnUrl;
        });
        let myUid = computed(() => {
            return store.state.uid;
        });
        let currentItem = props.current_item;
        const toUid = currentItem.uinfo.uid;
        let quantity = ref(currentItem.trans_count_lower_bound);
        let inputQuantity = ref(currentItem.trans_count_lower_bound);
        //限制輸入交易額
        const formatNumber = ($event) => {
            var keyCode = $event.key;
            console.log('--lb', $event, keyCode);
            if (
                keyCode >= 0 ||
                keyCode == "Backspace" ||
                keyCode == "ArrowLeft" ||
                keyCode == "ArrowRight"
            ) {
                $event.returnValue = true;
                return true;
            } else {
                $event.returnValue = false;
                return false;
            }
        };
        let N = computed(() => {
            return Math.floor(quantity.value / currentItem.round_multiple)
        });
        //金額必須是倍增基數的整數倍
        watch(quantity, (n, o) => {
            // console.log('--lb',n, o, N.value);
            if (n > N.value * currentItem.round_multiple) {
                inputQuantity.value = quantity.value = N.value * currentItem.round_multiple;
            }
            else {
                inputQuantity.value = quantity.value = n;
            }
            // 自定義的提示文字保持和組件的文字一致
            $(".detail_box .el-slider__button-wrapper .hover_tips").text(Math.floor(inputQuantity.value / currentItem.round_multiple) + " × " + currentItem.round_multiple);
        })
        // 檢查輸入框輸入
        const checkInputQuantity = () => {
            console.log('--lb', inputQuantity.value)
            // inputQuantity.value = inputQuantity.value.replace(/[\e|\+|\-]/gi, '');
            if (inputQuantity.value < currentItem.trans_count_lower_bound) {
                inputQuantity.value = currentItem.trans_count_lower_bound
            }
            else if (inputQuantity.value > currentItem.remain_count) {
                inputQuantity.value = currentItem.remain_count
            }
            if (currentItem.round_multiple > 0) {
                if (inputQuantity.value % currentItem.round_multiple != 0) {
                    const N = Math.floor(inputQuantity.value / currentItem.round_multiple);
                    console.log('--lb', N)
                    // console.log('--lb',N * currentItem.round_multiple)
                    inputQuantity.value = N * currentItem.round_multiple;
                }
            }
            quantity.value = inputQuantity.value;
        }


        //格式化時間
        const formatDateTime = (t) => {
            return common.formatDateTime(t);
        };
        //格式化channel圖標
        const icoHtml = common.formatChannelIcon(currentItem.trans_channels);

        //提交交易
        const comfirmOrder = () => {
            //檢查等級
            let myLevel = store.state.userInfo.levle;
            let limitLevel = store.state.MarketUserLevelLimit;
            if (
                currentItem.type == 1 &&
                limitLevel.create_public_buy_order_min_level > myLevel
            ) {
                toastr["error"]("Your level is below the requirement");
                return;
            } else if (
                currentItem.type == 2 &&
                limitLevel.create_public_sell_order_min_level > myLevel
            ) {
                toastr["error"]("Your level is below the requirement");
                return;
            }
            //檢查金額
            if (!quantity.value) {
                return;
            }
            if (quantity.value >= 0) {
                if (quantity.value == 0) {
                    toastr["error"]("The amount must be greater than 0!");
                }
                //金額要小於等於總數，大於等於最小交易額
                else if (
                    quantity.value > currentItem.remain_count ||
                    quantity.value < currentItem.trans_count_lower_bound
                ) {
                    toastr["error"](
                        "The amount must be less than or equal to " +
                        currentItem.remain_count +
                        " and greater than or equal to " +
                        currentItem.trans_count_lower_bound
                    );
                }
                //如果設置了倍增基數且輸入數不為整數倍時不提交
                else if (
                    currentItem.round_multiple > 0 &&
                    quantity.value % currentItem.round_multiple != 0
                ) {
                    return
                }
                else {
                    // toUid = currentItem.uinfo.uid;
                    const sendUrl =
                        common.userApi.requestUrl + "/finance_api/market/orders/" + toUid;
                    const sendHeaders = common.buildHeaders("form");
                    const sendData = common.buildSendData({
                        asset_type: 2,
                        count: quantity.value,
                        type: currentItem.type == 2 ? 1 : 2,
                        parent_order_sn: currentItem.sn,
                    });
                    common.showLoading("comfirmOrder");
                    axios
                        .post(sendUrl, sendData, {
                            headers: sendHeaders
                        })
                        .then((r) => {
                            console.log('--lb', r);
                            if (r.data.code == 0) {
                                toastr["success"](r.data.msg);
                            } else {
                                toastr["error"](r.data.msg);
                            }
                        })
                        .catch((e) => {
                            console.error(e);
                            toastr["error"](e);
                        })
                        .finally(() => {
                            common.hideLoading("comfirmOrder");
                            ctx.emit("updateHall", props.offset);
                            showInputTag.value = false;
                        });
                }
            } else {
                toastr["error"]("Please Enter Number!");
            }
        };

        //取消交易
        const cancelOrder = () => {
            const sendUrl =
                common.userApi.requestUrl +
                "/finance_api/market/public_orders/" +
                currentItem.sn +
                "/status";
            const sendHeaders = common.buildHeaders("form");
            const sendData = common.buildSendData({
                status: 1
            });
            common.showLoading("cancelOrder");
            axios
                .patch(sendUrl, sendData, {
                    headers: sendHeaders
                })
                .then((r) => {
                    console.log('--lb', r);
                    if (r.data.code == 0) {
                        toastr["success"](r.data.msg);
                    } else {
                        toastr["error"](r.data.msg);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    toastr["error"](e);
                })
                .finally(() => {
                    common.hideLoading("cancelOrder");
                    ctx.emit("updateHall", props.offset);
                    showInputTag.value = false;
                });
        };

        //打開聊天
        const openChat = () => {
            window.open("/chat?touid=" + toUid);
            // window.parent.postMessage({
            //     type: PostMessageType.Chat2User,
            //     toUser: {
            //       uid: toUid,
            //       nick: "xxxxx",
            //       headUrl: "yyyyy",
            //     }
            //   }, "*");
        };
        const openTalk = () => {
            common.openTalk(toUid);
        };

        onMounted(() => {
            isInElectron = common.isInElectron();
        });

        // 增加交易金額
        let addCountNum = ref();
        // 顯示追加輸入框
        let showAddCountInput = ref(false);
        const addCount = () => {
            common.confirm({
                type: 'info',
                title: '追加金幣',
                text: '',
                btnTxt: 'GO',
                showCancleBtn: true,
                confirmFn: function (r) {
                    showAddCountInput.value = false;
                    if (addCountNum.value && addCountNum.value > 0) {
                        const sendUrl =
                            common.userApi.requestUrl +
                            "/finance_api/market/public_orders/" + currentItem.sn + "/asset_count";
                        const sendHeaders = common.buildHeaders("form");
                        const sendData = common.buildSendData({
                            count: addCountNum.value
                        });
                        common.showLoading("my order addCount");
                        axios
                            .patch(sendUrl, sendData, {
                                headers: sendHeaders
                            })
                            .then((r) => {
                                console.log('--lb', r);
                                if (r.data.code == 0) {
                                    currentItem.remain_count += addCountNum.value;
                                    toastr["success"](r.data.msg);
                                } else {
                                    toastr["error"](r.data.msg);
                                }
                            })
                            .catch((e) => {
                                console.error(e);
                                toastr["error"](e);
                            })
                            .finally(() => {
                                common.hideLoading("my order addCount");
                                ctx.emit("updateHall", props.offset);
                                showInputTag.value = false;
                            });
                    }
                    else {
                        // toastr['error']()
                    }
                },
                cancelFn: function (r) {
                    showAddCountInput.value = false;
                    store.commit("set_showConfirmTag", false);
                }
            });
            showAddCountInput.value = true;
        }

        // 格式化滑塊的提示文字
        const formatTooltip = (inx) => {
            return Math.floor(inx / currentItem.round_multiple) + " × " + currentItem.round_multiple;
        }
        // 加減操作時顯示tips
        const showDarkTips = () => {
            $(".detail_box .el-slider__button-wrapper").append("<div class='hover_tips'>" + $(".el-popper.is-dark").text() + "</div>")
        }
        const hideDarkTips = () => {
            $(".detail_box .el-slider__button-wrapper .hover_tips").remove();
        }


        var lang = computed(() => {
            return store.state.lang;
        });
        var langTxt = store.state.text;

        return {
            cdnUrl,
            langTxt,
            lang,
            addCount, showAddCountInput, addCountNum,
            quantity,
            currentItem,
            formatDateTime,
            icoHtml,
            comfirmOrder,
            formatNumber,
            myUid,
            cancelOrder,
            showInputTag,
            openChat,
            openTalk,
            isInElectron, N, inputQuantity, checkInputQuantity, formatTooltip, showDarkTips, hideDarkTips
        };
    },
});