<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="5" fill="white" fill-opacity="0.2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.298 15H29.702C31.5225 15 33 16.6519 33 18.6926V26.2062C33 28.2466 31.5225 29.8984 29.702 29.8984L23.572 29.8981L20.3957 32.6108C20.0481 32.9075 19.6123 33.0426 19.1784 32.9882C18.7446 32.9337 18.346 32.6939 18.0652 32.3183L18.0194 32.2542C17.7924 31.922 17.6692 31.515 17.6692 31.0967V29.8312L17.6127 29.8189C16.1483 29.4726 15.0415 28.0378 15.0012 26.3046L15 26.2062V18.6926C15 16.6519 16.4775 15 18.298 15ZM19.4996 23.2286C19.9967 23.2286 20.3996 22.7911 20.3996 22.2515C20.3996 21.7118 19.9967 21.2743 19.4996 21.2743C19.0026 21.2743 18.5996 21.7118 18.5996 22.2515C18.5996 22.7911 19.0026 23.2286 19.4996 23.2286ZM24.8996 22.2515C24.8996 22.7911 24.4967 23.2286 23.9996 23.2286C23.5026 23.2286 23.0996 22.7911 23.0996 22.2515C23.0996 21.7118 23.5026 21.2743 23.9996 21.2743C24.4967 21.2743 24.8996 21.7118 24.8996 22.2515ZM28.4996 23.2286C28.9967 23.2286 29.3996 22.7911 29.3996 22.2515C29.3996 21.7118 28.9967 21.2743 28.4996 21.2743C28.0026 21.2743 27.5996 21.7118 27.5996 22.2515C27.5996 22.7911 28.0026 23.2286 28.4996 23.2286Z" fill="url(#paint0_linear_2948_173405)"/>
        <defs>
        <linearGradient id="paint0_linear_2948_173405" x1="14.9999" y1="32.9989" x2="34.6888" y2="30.8362" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8688"/>
        <stop offset="1" stop-color="#E9373E"/>
        </linearGradient>
        </defs>
        </svg>
</template>