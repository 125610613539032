import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";

import ui_select from "@/components/ui/ui_select";
import ui_page_btn from '@/components/ui/ui_page_btn'
import common from "../../../utils/common";
import axios from "axios";
import order_detail from '../order_detail/order_detail.vue'
import ui_btn_2 from '@/components/ui/ui_btn_2'
import feedback from '@/components/feedback/feedback'
import icon_chat from '@/components/ui/icon/icon_chat.vue'

export default defineComponent({
    name: "tradingHall",
    props: {},
    components: { ui_select, ui_page_btn, order_detail, ui_page_btn, ui_btn_2, feedback,icon_chat },
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();

        let isInElectron = common.isInElectron();
        let myUid = computed(() => {
            return store.state.uid
        });
        //交易列表的类型：所有、出售、求购
        let typeList = [
            {
                name: 'All',
                val: 0,
                default: true,
            },
            {
                name: "Buy <i style='display:inline-block;width:0.08rem;height:0.08rem;background: #FFB710;margin-left: 0.1rem;'></i>",
                val: 2,
                default: false,
            },
            {
                name: "Sell  <i style='display:inline-block;width:0.08rem;height:0.08rem;background: #C1FB00;margin-left: 0.1rem;'></i>",
                val: 1,
                default: false,
            },
            {
                name: 'Completed',
                val: -1,
                default: false,
            },
        ];
        //交易列表的排序：最新、最旧
        let orderBy = [
            {
                name: "Ascending",
                val: 0,
                default: true,
            },
            {
                name: "Descending",
                val: 1,
                default: false,
            },
        ];
        //默认交易类型：Buy
        let showType = ref(0);
        //默认排序：降序
        let asc = ref(0);
        watch(showType, (newValue, oldValue) => {
            console.log('--lb', "showType changed", newValue, oldValue);
            getMyOrders(0);
        });
        watch(asc, (newValue, oldValue) => {
            console.log('--lb', "asc changed", newValue, oldValue);
            getMyOrders(offset.value);
        });

        let ordersList = ref([]);
        //获取交易列表
        let offset = ref(0);//偏移量(即当前页减1)，可选默认为0，最大值只支持2000。
        let count = ref(20); //拉取的数量，可选，默认为30，最大每次不得超过50
        let dataList = ref([]);
        const getMyOrders = (currentPage) => {
            offset.value = currentPage;
            let typeStr = '';
            //获取全部类型的订单
            if (showType.value == 0) {
                typeStr = '&statuses=0,1,2,3,4,5';
            }
            //获取buy类型的订单
            else if (showType.value == 2) {
                typeStr = '&type=1&statuses=0,1';
            }
            //获取sell类型的订单
            else if (showType.value == 1) {
                typeStr = '&type=2&statuses=0,1';
            }
            //获取completed类型的订单
            else if (showType.value == -1) {
                typeStr = '&statuses=2,3,4,5';
            }
            const sendUrl = common.userApi.requestUrl + '/finance_api/market/orders?statist=1&asc=' + asc.value + '&offset=' + offset.value * count.value + '&count=' + count.value + typeStr;
            const sendHeaders = common.buildHeaders();
            const sendData = {
                headers: sendHeaders,
            };
            common.showLoading('getMyOrders')
            axios.get(sendUrl, sendData).then((r) => {
                if (r.data.code == 0) {
                    dataList.value = r.data.infos;
                    console.log('--lb', 'dataList', r.data.infos)
                }
                else {
                    toastr['error'](r.data.msg);
                }

            }).catch((e) => {
                toastr['error'](e);
            }).finally(() => {
                common.hideLoading('getMyOrders')
            })

        }
        //组件加载后自动查询数据
        // getMyOrders(0);
        if (common.isInElectron()) {
            common.showLoading('isInElectron timeout');
            setTimeout(function () {
                getMyOrders(0);
                common.hideLoading('isInElectron timeout');
            }, 1000);
        }
        else {
            getMyOrders(0);
        }


        //格式化时间
        const formatDateTime = (t) => {
            return common.formatDateTime(t);
        }
        //格式化channel图标
        const formatChannelIcon = (icoArray) => {
            return common.formatChannelIcon(icoArray);
        }

        //显示交易详情
        //选定查看的交易信息
        let currentItem = ref(null)
        const showOrderDetail = (sn) => {
            const sendUrl = common.userApi.requestUrl + "/finance_api/market/public_orders?sn=" + sn;
            common.showLoading('my trades showDetail');
            axios.get(sendUrl, { headers: common.buildHeaders('form') }).then((r) => {
                if (r.data.code == 0) {
                    currentItem.value = r.data.infos[0];
                }
                else {
                    toastr['error'](r.data.msg);
                }
            }).catch((e) => {
                toastr['error'](e)
            }).finally(() => {
                common.hideLoading('my trades showDetail');
            });
        }

        //修改订单状态
        const setOrderStatus = (item, toStatus, confirmTag = false, tipsTxt = '') => {
            if (!confirmTag) {
                const sendUrl = common.userApi.requestUrl + "/finance_api/market/orders/" + item.sn + "/status";
                const sendHeaders = common.buildHeaders('form');
                const sendData = common.buildSendData({ status: toStatus });
                common.showLoading("my trades setOrderStatus");
                axios.patch(sendUrl, sendData, { headers: sendHeaders }).then((r) => {
                    console.log('--lb', r);
                    if (r.data.code == 0) { toastr['success'](r.data.msg); }
                    else {
                        toastr['error'](r.data.msg);
                    }
                }).catch((e) => {
                    console.error(e);
                    toastr['error'](e);
                }).finally(() => {
                    common.hideLoading("my trades setOrderStatus");
                    getMyOrders(offset.value);
                })
            }
            else {
                common.confirm({
                    type: 'info',
                    title: 'Tips',
                    text: tipsTxt,
                    btnTxt: 'Confirm',
                    showCancleBtn: true,
                    confirmFn: function (r) {
                        setOrderStatus(item, toStatus, false, '');
                    },
                    cancelFn: function (r) {
                        store.commit("set_showConfirmTag", false);
                    }

                });
            }
        }

        // 游戏反馈
        // /public/upload
        let showfeedbackTag = ref(false);
        const arbitrating = () => {
            showfeedbackTag.value = true;
        }

        //打开聊天
        const openChat = (fromUid, toUid) => {
            if (fromUid == myUid.value) { window.open("/chat?touid=" + toUid); }
            else {
                window.open("/chat?touid=" + fromUid);
            }
        };
        const openTalk = (fromUid, toUid) => {
            let tou;
            if (fromUid == myUid.value) { window.open(tou = toUid); }
            else {
                tou = fromUid;
            }
            common.openTalk(tou);
        };



        onMounted(() => {
            isInElectron = common.isInElectron();
        });
        var lang = computed(() => {
            return store.state.lang;
        });
        var langTxt = store.state.text;

        return {
            langTxt,
            lang, typeList, showType, asc, orderBy, ordersList, formatDateTime, showOrderDetail, formatChannelIcon, offset, count, currentItem, getMyOrders, dataList, myUid, setOrderStatus, arbitrating, showfeedbackTag, openChat, openTalk, isInElectron,
        };
    },
});